import React, { useState } from 'react';
import EmptyList from '../../components/common/EmptyList';
import BlogList from '../../components/Home/BlogList';
import Header from '../../components/Home/Header';
import Footer from '../../containers/Footer';
import { Pagination } from '@mui/material';
import { API_BASE_URL} from '../../config'

const Home = () => {
  const postsPerPage = 15;
  const [blogs, setBlogs] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  React.useEffect(() => {
    const url = `${API_BASE_URL}/api/blogs?page=${page}`;
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setBlogs(data.blogs);
        setTotalPages(Math.ceil(data.total_posts / postsPerPage));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [page]);


  const handlePageChange = (event, value) => {
    setPage(value)
    window.scrollTo(0, 0);
  }

  function handleBlogChange(blogs){
    setBlogs(blogs)}

  return (
    <div>
      {/* Page Header */}
      <Header onBlogChange={handleBlogChange} />

      {/* Blog List & Empty View */}
      {!blogs.length ? <EmptyList /> : <BlogList blogs={blogs} />}

      {/* Pagination */}
      <div sx={{ display: 'flex', justifyContent: 'center' }}>
        <Pagination 
          count={totalPages} 
          page={page} 
          onChange={handlePageChange}
          size="large"
          color="primary"
          showFirstButton
          showLastButton
          sx={{ '& ul': { justifyContent: 'center', marginTop: "4rem" } }} />
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Home;
