import React from 'react';
import './styles.css';
import Grid from '@mui/material/Grid';
import AppBarContainer from '../../../containers/AppBarContainer';
import SearchBar from '../SearchBar';
import { useMediaQuery } from '@mui/material';
import { API_BASE_URL} from '../../../config'

function Header(props) {
  const [searchKey, setSearchKey] = React.useState('');
  const isSmallScreen = useMediaQuery('(max-width:900px)');

  const fetchSearchResults = async (query) => {
    try {
      const url = `${API_BASE_URL}/search?q=${query}`;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      props.onBlogChange(data.blogs);
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };

  const handleSearchResults = () => fetchSearchResults(searchKey);
  
  //  Clear search
  const handleClearSearch = () => {
    setSearchKey('');
    fetchSearchResults('');
  };

  // Search submit
  const handleSearchBar = (e) => {
    e.preventDefault();
    handleSearchResults();
  };

  return (
    <header className='home-header'>
      <AppBarContainer />
      <Grid container spacing={2} alignItems="center" justifyContent="space-around">
        <Grid item xs={12} sm={12} md={6} sx={{ mt: 5 }}>
          <h2>»»——— ★ ———««</h2>
          <h1>
            Demystifying Natural Language Processing
          </h1>
          <p>
            From foundations to advanced techniques <br /> 
            All the NLP insights you need—boiled down, simplified, and packed into a 2-minute read.
          </p>
          <SearchBar
            value={searchKey}
            clearSearch={handleClearSearch}
            formSubmit={handleSearchBar}
            handleSearchKey={(e) => setSearchKey(e.target.value)}
          />
        </Grid>
        {isSmallScreen ? null : (
          <Grid item xs={12} sm={12} md={6}>
            <img src="/assets/images/banner-home.webp" loading="lazy" alt="Logo" width="100%"/>
          </Grid>
        )}
      </Grid>
    </header>
  )
};

export default Header;
