import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Chip from '../../components/common/Chip';
import EmptyList from '../../components/common/EmptyList';
import './styles.css';
import Footer from '../../containers/Footer';
import AppBarContainer from '../../containers/AppBarContainer';
import { API_BASE_URL } from '../../config';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { okaidia } from 'react-syntax-highlighter/dist/esm/styles/prism';

const Blog = () => {
  const { path } = useParams();
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    // fetch data from server
    const url = `${API_BASE_URL}/blog/${path}`;
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setBlog(data.blog);
        // Metadata
        document.title = `${data.blog.title} - NLP Explained`;
        const metaDescription = `${data.blog.description} - NLP Explained`;
        const metaTag = document.querySelector('meta[name="description"]');
        if (metaTag) {
          metaTag.setAttribute('content', metaDescription);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [path]);

  const renderContentWithCodeHighlighting = (content) => {
    const codeRegex = /<code>([\s\S]*?)<\/code>/g;
    const parts = content.split(codeRegex);

    return parts.map((part, index) => {
      if (index % 2 === 1) {
        // Replace <p> and </p> with newline characters
        const cleanedCode = part
          .replace(/<\/?p>/g, '\n');

        return (
          <SyntaxHighlighter
            language="python"
            style={okaidia}
            customStyle={{
              backgroundColor: '#2d2d2d', // dark black background
              fontSize: '14px', // smaller font size
              padding: '1em', // padding inside code block
              overflowX: 'auto', // horizontal scroll for long lines
              border: null, // no border
              borderRadius: '10px', // rounded corners
            }}
            key={index}
          >
            {cleanedCode.trim()}
          </SyntaxHighlighter>
        );
      }
      return <div key={index} dangerouslySetInnerHTML={{ __html: part }} />;
    });
  };

  return (
    <>
      <AppBarContainer />
      {blog ? (
        <div className='blog-wrap'>
          <header>
            <p className='blog-date'>Published {blog.createdAt}</p>
            <h1>{blog.title}</h1>
            <div className='blog-subCategory'>
              {blog.subCategory.map((category, i) => (
                <div key={i}>
                  <Chip label={category} />
                </div>
              ))}
            </div>
          </header>
          <img src={API_BASE_URL + blog.cover} loading="lazy" alt='cover' />
          <div className='blog-desc'>
            {renderContentWithCodeHighlighting(blog.content)}
          </div>
          <Footer />
        </div>
      ) : (
        <EmptyList />
      )}
    </>
  );
};

export default Blog;
