import React from 'react';

import './styles.css';
import Footer from '../../containers/Footer';
import AppBarContainer from '../../containers/AppBarContainer';


const Credits = () => {

  return (
    <>
      <AppBarContainer />

        <div className='blog-wrap'>
          <header>
            <p style={{ padding: '10px', margin: '30px' }} className='blog-date'>Credits</p>
            <div  style={{textAlign: "left"}}>
              - <a href="https://www.freepik.com/free-vector/brain-artificial-intelligence-logo-design-ai-technology-brainstorm-logotype_14240984.htm#query=brain%20logo&position=45&from_view=search&track=ais">Image by sentavio</a> on Freepik<br />
              - <a href="https://www.freepik.com/free-vector/modern-cute-chatbot-different-poses-flat-set_13146592.htm#query=language%20AI&position=30&from_view=search&track=ais">Image by pch.vector</a> on Freepik<br />
              - <a href="https://www.freepik.com/free-vector/hand-drawn-rpa-illustration_21902265.htm#query=machine%20learning&position=45&from_view=search&track=ais">Image from Freepik</a><br />
              - <a href="https://www.freepik.com/free-vector/hand-drawn-starting-line-business-illustration_29765448.htm#query=getting%20started&position=0&from_view=search&track=ais">Image from Freepik</a><br />
              - <a href="https://www.freepik.com/free-vector/self-management-life-coaching-man-doubting-questioning-brainstorming-identity-crisis-delirium-mental-confusion-confused-feelings-concept_10782550.htm#query=why%20it%20matters&position=10&from_view=search&track=ais">Image by vectorjuice</a> on Freepik<br />
              - <a href="https://www.freepik.com/free-vector/sentiment-analysis-concept-illustration_12892943.htm#query=sentiment&position=0&from_view=search&track=sph">Image by storyset</a> on Freepik<br />
              - <a href="https://www.freepik.com/free-vector/hand-drawn-colorful-space-background_4792337.htm#query=universe&position=30&from_view=search&track=sph">Image by pikisuperstar</a> on Freepik<br />
              - <a href="https://www.freepik.com/free-vector/cartoon-tiny-people-having-international-communication-online-flat-illustration_14623766.htm#query=translation&position=3&from_view=search&track=sph">Image by pch.vector</a> on Freepik<br />
              - <a href="https://www.freepik.com/free-vector/webinar-concept-illustration_12079894.htm#query=introduction&position=0&from_view=search&track=sph">Image by storyset</a> on Freepik<br />
              - <a href="https://www.freepik.com/free-vector/man-teaching-maths-lesson-class-boy-answering-blackboard-young-man-standing-student-writing-formula-mathematics-class_24023226.htm#query=explain%20to%20children&position=0&from_view=search&track=ais">Image by studio4rt</a> on Freepik<br />
              - <a href="https://www.freepik.com/free-vector/ethical-dilemma-illustration_10883223.htm#query=ethics&position=2&from_view=search&track=sph">Image by pikisuperstar</a> on Freepik<br />
              - <a href="https://www.freepik.com/free-vector/voice-control-concept-illustration_7140434.htm#query=siri%20alexa&position=4&from_view=search&track=ais">Image by storyset</a> on Freepik<br />
              - <a href="https://www.freepik.com/free-vector/marketing-concept-illustration_10802152.htm#query=marketing&position=14&from_view=search&track=sph">Image by storyset</a> on Freepik<br />
              - <a href="https://www.freepik.com/free-vector/organic-flat-feedback-concept_13961232.htm#query=customer%20satisfaction&position=4&from_view=search&track=ais">Image by pikisuperstar</a> on Freepik<br />
              - <a href="https://www.freepik.com/free-vector/scientist-doing-experiment-science-lab_20438839.htm#query=the%20science%20behind&position=16&from_view=search&track=ais">Image by brgfx</a> on Freepik<br />
              - <a href="https://www.freepik.com/free-vector/man-look-graphic-chart-business-analytics-concept-big-data-processing-icon_3629600.htm#query=business%20intelligence&position=1&from_view=search&track=ais">Image by fullvector</a> on Freepik<br />
              - <a href="https://www.freepik.com/free-vector/archaeologist-discovers-old-building-illustration-archeological-excavation-history-science-expedition-historian-reading-map_23591629.htm#query=tools&position=13&from_view=search&track=sph">Image by studio4rt</a> on Freepik<br />
              - <a href="https://www.freepik.com/free-vector/flat-bankruptcy-concept-flat-design_7476151.htm#query=limitations%20challenges&position=3&from_view=search&track=ais">Image from Freepik</a><br />
              - <a href="https://www.freepik.com/free-vector/tiny-scientists-developing-ai-using-machine-learning-brain-computing-data-flat-vector-illustration-artificial-intelligence-technology-science-concept-banner-website-design-landing-web-page_23548216.htm#query=natural%20language%20processing&position=2&from_view=search&track=ais&uuid=1a70a25e-d550-4334-8913-ecaca6d91d3e">Image by pch.vector</a> on Freepik<br />
              - <a href="https://pixabay.com/users/kaboompics-1013994/?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=791109">Image by Karolina Grabowska</a> from <a href="https://pixabay.com//?utm_source=link-attribution&utm_medium=referral&utm_campaign=image&utm_content=791109">Pixabay</a><br />
              - <a href="https://www.freepik.com/free-vector/warehouse-worker-man-towing-hand-fork-lifter_1311297.htm#query=boxes&position=18&from_view=search&track=sph&uuid=4dbb3aa6-6938-43ac-b020-84c889eed11f">Image by iconicbestiary</a> on Freepik<br />
              - <a href="https://www.freepik.com/free-vector/data-processing-concept-illustration_12219361.htm#query=data%20processing&position=0&from_view=search&track=ais&uuid=baa091cd-2c4d-40ca-bafe-4764eb3af591">Image by storyset</a> on Freepik<br />
              - <a href="https://www.freepik.com/free-vector/thinking-face-concept-illustration_20908904.htm#query=difference&position=10&from_view=search&track=sph&uuid=7f957410-65c7-40b8-a964-b20d773097f3">Image by storyset</a> on Freepik<br />
              - <a href="https://www.freepik.com/free-vector/isolated-justice-scales-symbol-white-background_30833713.htm#query=balance&position=5&from_view=search&track=sph&uuid=c9d506b3-2bd3-4faf-a5e2-2c1395c5d580">Image by brgfx</a> on Freepik<br />
              - <a href="https://www.freepik.com/free-vector/five-star-grading-evaluation-rating-estimating-excellent-review-customer-satisfaction-with-service-highest-score-client-feedback_12083157.htm#query=evaluation&position=47&from_view=search&track=sph&uuid=fad84fc7-b127-42f2-9317-d479f50b3e18">Image by vectorjuice</a> on Freepik
              - <a href="https://www.freepik.com/free-vector/mathematics-concept-illustration_10733824.htm#query=maths&position=20&from_view=search&track=sph&uuid=423eeecd-a632-439e-816e-3c19cd9d93a7">Image by storyset</a> on Freepik<br />
              - <a href="https://www.freepik.com/free-vector/hand-drawn-black-teacher-clipart-illustration_78537599.htm#query=beginner%20learner&position=28&from_view=search&track=ais&uuid=1d6d8dbe-74cb-485f-b7f8-10b4f683ef36">Image by Freepik</a><br />
              - <a href="https://www.freepik.com/free-vector/ai-powered-content-creation-isometric-concept-with-chatbot-laptop-screen-3d-vector-illustration_43868977.htm#query=generate&position=30&from_view=search&track=sph&uuid=cb45323d-6cee-47e6-afb8-dc0ac02aac80">Image by macrovector</a> on Freepik<br />
              - <a href="https://www.freepik.com/free-photo/dictionary-definition-word_20989122.htm#query=semantic&position=0&from_view=search&track=sph&uuid=521344ad-afa1-4f88-ae61-e3fd94ee4d8a">Image by fabrikasimf</a> on Freepik<br />
              - <a href="https://www.freepik.com/free-vector/notes-concept-illustration_6183538.htm#query=summary&position=7&from_view=search&track=sph&uuid=1283a46f-f986-4b17-a5c9-c6824a7657ab">Image by storyset</a> on Freepik<br />
              - <a href="https://www.freepik.com/free-photo/abstract-background-with-low-poly-design-with-connecting-lines-dots_1219696.htm#query=neural%20networks&position=3&from_view=search&track=ais&uuid=ad791be9-5738-4218-977e-86230060f4b8">Image by kjpargeter</a> on Freepik<br />
              - <a href="https://www.freepik.com/free-vector/memory-storage-concept-illustration_10117876.htm#fromView=search&page=1&position=9&uuid=236fa787-5407-4a1e-87dd-0e4e0295e316">Image by storyset</a> on Freepik<br />
              - <a href="https://www.freepik.com/free-vector/database-digital-information-storage-organization-technical-support-worker-cartoon-character-seo-optimization-computer-hardware-vector-isolated-concept-metaphor-illustration_12083512.htm#fromView=search&page=1&position=9&uuid=9f7a18f2-37db-4dce-b9b4-248a01e6f694">Image by vectorjuice</a> on Freepik<br />
            </div>
          </header>
          <Footer />
        </div>

    </>
  );
};

// function Credis() {
//   return (
//     <div>
//       <Typography variant="h4" gutterBottom>
//         Credits
//       </Typography>
//       <Typography variant="body1" gutterBottom>
//         This project was created by John Doe.
//       </Typography>
//       <Typography variant="body1" gutterBottom>
//         Icons made by <Link href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</Link> from <Link href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</Link>.
//       </Typography>
//     </div>
//   );
// }

export default Credits;
